@tailwind base;
@tailwind components;
@tailwind utilities;

button[aria-label="Download"],
button[aria-label="Print"] {
  display: none !important;
}

@layer utilities {
  @keyframes slideIn {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .animate-slide-in {
    animation: slideIn 0.5s ease-in-out;
  }

  .nav-link {
    @apply rounded-xl border-2 border-transparent p-2 text-neutral-900 hover:border-[#191970] hover:text-[#191970];
  }

  .bg-primary {
    @apply bg-[#191970];
  }

  .leaflet-container {
    height: 100%;
    width: 100%;
  }

  .leaflet-control-attribution.leaflet-control {
    display: none !important;
  }

  ol {
    list-style-type: decimal;
  }

  /* PDF Css */
  /* .pdf-viewer-container {
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .pdf-viewer {
    width: 100%;
    height: 100%;
  }

  .rpv-core__viewer {
    max-width: 100% !important;
  }

  @media (max-width: 768px) {
    .pdf-viewer {
      width: 100%;
      height: calc(100vh - 56px); /* Adjust height for mobile view */
  /*}
  } */

  /* CSS For Stars Rating*/
  .comment-stars {
    display: flex;
    flex-direction: row-reverse;
  }

  .comment-stars-input {
    display: none;
  }

  .comment-stars-input:checked ~ .comment-stars-view svg {
    fill: #ffc41f;
  }

  .comment-stars-input:checked ~ .comment-stars-view:hover svg,
  .comment-stars-input:checked
    ~ .comment-stars-view:hover
    ~ .comment-stars-view
    svg {
    fill: ##ffd560;
  }

  .comment-stars-view {
    cursor: pointer;
  }

  .comment-stars-view svg {
    fill: #ededef;
    width: 1.39306640625em;
    height: 1.5em;
  }

  .comment-stars-view.is-half {
    transform: translateX(100%);
    margin-left: -0.69580078125em;
  }

  .comment-stars-view.is-half svg {
    width: 0.69580078125em;
  }

  button[aria-label="Download"],
  button[aria-label="Print"] {
    display: none !important;
  }

  /* .comment-stars-view:hover svg,
  .comment-stars-view:hover ~ .comment-stars-view svg {
    fill: #ffd560;
  } */

  p[style*="text-align: center;"] img {
    display: inline-block;
    margin: 0 auto;
  }
}

@layer base {
  html {
    scroll-behavior: smooth;
  }

  :root {
    --primary: 240 64% 27%;
    --primary-foreground: 0 0% 100%;

    --secondary: 240 33% 98%;
    --secondary-foreground: 0 0% 31%;
  }
}

@supports (-webkit-touch-callout: none) {
  .hidden-in-safari {
    @apply hidden;
  }
}
